import store from '@/store'
import router from '@/router'

export default function useUserHandler() {
  const updateUser = userData => {
    console.log(userData)
    try {
      store.dispatch('app-user/updateUser', { id: userData.id, userData })
        .then(response => {
          router.push({ name: 'apps-users-view', params: { id: response.data.id } })
        })
        .catch(() => {
          window.alert('error in upserting user data, please contact the administrator')
        })
    } catch (e) {
      window.alert('error in upserting user data, please contact the administrator')
    }
  }
  return {
    updateUser,
  }
}
